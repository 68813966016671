import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React, { useContext, useEffect } from "react";
import { toast } from "react-toastify";
import CartItemSummary from "../components/partials/cart-item-summary";
import CompareProducts from "../components/partials/compare-products";
import { LinerStyles } from "../components/partials/liner-styles";
import RecommendedProductBox from "../components/partials/recommended-product-box";
import Seo from "../components/seo";
import CartContext from "../context/cart-context";
import useRushOrderProduct from "../hooks/useRushOrderProduct";
import classNames from "classnames";
import { styles } from "../utilities/styles";

function AddAccessoriesToYourCart() {
  const cartContext = useContext(CartContext);

  function addToCartNotify(text) {
    toast.success(text);
  }

  useEffect(() => {
    toast.success("Added cover(s) to cart");
  }, []);

  const rushOrderProduct = useRushOrderProduct();

  const cartHasCategory = (category) => {
    return cartContext.contents.some((item) =>
      item.category.includes(category)
    );
  };

  let recommendedProducts = cartContext.recommendedProducts()
    ? cartContext.recommendedProducts()
    : [];

  if (!cartHasCategory("covers")) {
    recommendedProducts = recommendedProducts.filter(({ node }) => {
      return (
        node.slug !== "ez-lift-systems" && node.slug !== "window-well-locks"
      );
    });
  }

  return (
    <>
      <Seo title="Window Well Cover Accessories" />
      <div className="container px-4 mx-auto my-8 md:my-16">
        <div>
          <h1>Window Well Covers Added to Cart</h1>
          <p>Be sure to check out our accessories to build out your covers!</p>
        </div>
        <div
          className={`${
            (cartContext.totals.countItems && recommendedProducts.length) ||
            rushOrderProduct
              ? "flex flex-wrap"
              : "hidden"
          } mt-8`}
        >
          <div className="pb-4 w-full text-grey-darkest">
            <div className="flex flex-wrap">
              {recommendedProducts.map((product) => {
                return (
                  <RecommendedProductBox
                    key={product.node.id}
                    product={product}
                    addToCartNotify={addToCartNotify}
                  />
                );
              })}
              {rushOrderProduct && (
                <RecommendedProductBox
                  key={rushOrderProduct.node.id}
                  product={rushOrderProduct}
                  addToCartNotify={addToCartNotify}
                />
              )}
            </div>
          </div>
        </div>
        {cartHasCategory("covers") && !cartHasCategory("liners") && (
          <>
            <div className="px-4">
              <h2 className="text-center py-4">Choose Your Scene</h2>
              <div className="flex justify-center">
                <div className="md:w-1/2">
                  <StaticImage
                    alt="window well liner"
                    src={"../../static/images/liners/liner-interior-shot.jpg"}
                  />
                </div>
              </div>
            </div>

            <LinerStyles header="" />
          </>
        )}
        {!cartHasCategory("covers") && cartHasCategory("liners") && (
          <CompareProducts compact={true} />
        )}
        <div className="my-4 md:mx-4">
          <h2>Your Cart</h2>
          <div className="flex flex-wrap mt-2 md:mx-8">
            <div className="md:p-4 w-full">
              {cartContext.contents.map((value, index) => {
                return (
                  <CartItemSummary
                    key={index}
                    id={value.id}
                    url={value.url}
                    image={value.image}
                    price={value.salePrice ? value.salePrice : value.basePrice}
                    title={value.title}
                    quantity={value.quantity}
                    attributes={value.attributes ? value.attributes : []}
                  />
                );
              })}
              <div
                className={`overflow-y-scroll p-8 ${
                  cartContext.contents.length ? "hidden" : ""
                }`}
              >
                <h3>Your cart is empty</h3>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-end py-4 md:px-0 md:mr-4 uppercase">
          <Link
            className={classNames(
              "block md:inline-block text-sm px-4 py-3 no-underline rounded",
              styles.button.default
            )}
            to="/checkout/"
          >
            Go To Checkout
          </Link>
        </div>
      </div>
    </>
  );
}

export default AddAccessoriesToYourCart;
